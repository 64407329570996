import React from 'react'
import "./index.scss"
import accessCard from '../../assets/accept-card.png'
import svg1 from "../../assets/svg1.svg"
import svg2 from "../../assets/svg2.svg"
import svg3 from "../../assets/svg3.svg"
import svg4 from "../../assets/svg4.svg"
import svg5 from "../../assets/svg5.svg"

const LicenseBlock = () => {
    return (
        <div className='license-block'>
            {/* <img className='license-block__card' src={accessCard}/> */}
            {/* <span className='license-block__description'>Пример лицензии в Санкт-Петербурге</span> */}
            <div className='license-block__alert'>
                <p className='license-block__alert-text'>
                    Для оформления не нужно никуда ехать! Всего 5 минут оформления онлайн и уже через 10 рабочих дней забираете готовую лицензию
                </p>
                <div className='license-block__alert-top'>
                    ВАЖНО
                </div>
            </div>
            <div className='license-block__steps'>
                <p className='license-block__steps-title'>Как получить лицензию</p>
                <div className='license-block__steps-wrapper'>
                    <div className='license-block__step'>
                        <div className='license-block__step-left license-block__step-left_not-last'>
                            <img src={svg1} alt="icon-1"  className='license-block__step-img'/>
                        </div>
                        <div className='license-block__step-right'>
                            <p className='license-block__step-title'>Заполняете форму за 5 минут</p>
                            <span className='license-block__step-description'>Отправляете нужные документы через форму</span>
                        </div>
                    </div>
                    <div className='license-block__step'>
                        <div className='license-block__step-left license-block__step-left_not-last'>
                            <img src={svg2} alt="icon-1"  className='license-block__step-img'/>
                        </div>
                        <div className='license-block__step-right'>
                            <p className='license-block__step-title'>Оплачиваете лицензию</p>
                            <span className='license-block__step-description'>Налично/безналичный расчёт</span>
                        </div>
                    </div>
                    <div className='license-block__step'>
                        <div className='license-block__step-left license-block__step-left_not-last'>
                            <img src={svg3} alt="icon-1"  className='license-block__step-img'/>
                        </div>
                        <div className='license-block__step-right'>
                            <p className='license-block__step-title'>Проверка документов</p>
                            <span className='license-block__step-description'>Проверяем документы в день получения заявки.</span>
                        </div>
                    </div>
                    <div className='license-block__step'>
                        <div className='license-block__step-left license-block__step-left_not-last'>
                            <img src={svg4} alt="icon-1"  className='license-block__step-img'/>
                        </div>
                        <div className='license-block__step-right'>
                            <p className='license-block__step-title'>Оформляем вашу лицензию</p>
                            <span className='license-block__step-description'>До 10 рабочих дней</span>
                        </div>
                    </div>
                    <div className='license-block__step'>
                        <div className='license-block__step-left'>
                            <img src={svg5} alt="icon-1"  className='license-block__step-img'/>
                        </div>
                        <div className='license-block__step-right'>
                            <p className='license-block__step-title'>Ваша лицензия готова</p>
                            <span className='license-block__step-description'>Забираете лицензию и официально работаете в такси</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LicenseBlock