import React from 'react'
import cardIcom from "../../assets/cars.jpg"
import "./index.scss"
import Button from '../Button'

const BottomBanner = ({ openModal }) => {
    return (
        <div id='bottom-banner' className='bottom-banner'>
            <div className='bottom-banner__container'>
                <div className='bottom-banner__body'>
                    <p className='bottom-banner__title'>Оформи лицензию онлайн и забирай готовую через 10 дней</p>
                    <div className='bottom-banner__buttons'>
                        <Button onClick={openModal} outlined>
                            Обратный звонок
                        </Button>
                    </div>
                </div>
                <img className='bottom-banner__img' src={cardIcom} alt="" />
            </div>
        </div>
    )
}

export default BottomBanner