import React from 'react'
import "./index.scss"
import Button from '../Button'

const Header = ({ openModal }) => {
    return (
        <div className='header'>
            <p className='header__title'>Лицензия такси</p>
            <ul className='header__links'>
                <li className='header__link'><a href="#license">Зачем нужна лицензия</a></li>
                {/* <li className='header__link'><a href="#reviews">Отзывы</a></li> */}
                <li className='header__link'><a href="#price">Стоимость</a></li>
            </ul>
            <div className='header__buttons'>
                {/* <Button mini link href="#footer">Оформить онлайн</Button> */}
                <Button mini outlined onClick={openModal}>Обратный звонок</Button>
            </div>
        </div>
    )
}

export default Header