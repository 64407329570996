import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./styles.scss"
import MainPage from './pages/MainPage';
import PolicyPage from './pages/PolicyPage';

function App() {


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<MainPage/>}/>
          <Route path='/policy' element={<PolicyPage/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
