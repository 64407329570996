import React from 'react'
import PolicyHeader from '../components/PolicyHeader'
import PolicyContent from '../components/PolicyContent'

const PolicyPage = () => {
    return (
        <div>
            <PolicyHeader/>
            <PolicyContent/>
        </div>
    )
}

export default PolicyPage