import React from 'react'
import "./index.scss"
import gosIcon from "../../assets/gos.png"
import carIcon from "../../assets/car.png"
import docsIcon from "../../assets/papersSvg.svg"

const WhyLicense = () => {
    return (
        <div id='license' className='info-block'>
            <div className='info-block__why'>
                <div className='info-block__why-background'>
                    <p className='info-block__why-title'>
                        Почему важно иметь лицензию?
                    </p>
                    <div className='info-block__why-container'>
                        <div >
                            <p className='info-block__why-text'>
                                Оказание услуг такси без лицензии влечет за собой штраф в размере до пятидесяти тысяч рублей + конфискация транспортного средства (14.1.2 статья, пункт 2 - Осуществление предпринимательской деятельности в области транспорта без лицензии)
                            </p>
                            <p className='info-block__why-text'>
                                Разрешение на таксомоторную деятельность – это не только возможность легально предоставлять услуги. Имея лицензию, вы можете подключиться к любому из агрегаторов, представленных в городе. Также разрешение позволяет водителю заезжать на выделенные полосы, что дает преимущество в больших городах, где бывают пробки.
                            </p>
                        </div>
                        <img className='info-block__why-icon' src={gosIcon} alt="gosIcon" />
                    </div>
                </div>
            </div>
            <div className='info-block__safety'>
                <div className='info-block__safety-container'>
                    <img className='info-block__safety-image' src={carIcon} alt="" />
                    <p className='info-block__safety-text'>
                        Чтобы безопасно работать в такси и не попасть под штрафы - Оформляйте лицензию за 3000 рублей с гарантией на 5 лет
                    </p>
                </div>
            </div>
            <div className='info-block__docs'>
                <p className='info-block__docs-title'>
                    Оформление документов - берём на себя
                </p>
                <p className='info-block__docs-text'>
                    Не нужно самостоятельно делать путевые листы и медицинские справки.
                </p>
                <p className='info-block__docs-text'>
                    Под ключ вы получите необходимый пакет документов вместе с лицензией.
                </p>
                <div className='info-block__docs-icon-wrapper'>
                    <img className='info-block__docs-icon' src={docsIcon} alt="docsIcon" />
                </div>
            </div>
        </div>
    )
}

export default WhyLicense