import React from 'react'
import "./index.scss"

const PolicyHeader = () => {
    return (
        <div className='policy-header'>
            <p className='policy-header__text'>
                Политика конфиденциальности
            </p>
        </div>
    )
}

export default PolicyHeader