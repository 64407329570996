import React from 'react'
import "./index.scss"

const Button = ({ children, mini, outlined, black, className, link, ...props }) => {



    return (
        React.createElement(
            !link ? "button" : "a", 
            {
                className: `button ${mini && "button_theme_mini"} ${outlined && "button_theme_outlined"} ${black && "button_theme_black"} ${className}`,
                ...props
            },
            children
        )
    )
}

export default Button