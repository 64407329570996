import React from 'react'
import "./index.scss"
import { Link } from 'react-router-dom'

const PolicyContent = () => {
    return (
        <div className='policy-content'>
            <p className='policy-content__text'>
                Пользователь, оставляя заявку на интернет-сайте
                <br/>
                <br/>
                https://такси-56.рф
                <br/>
                <br/>

                Принимает настоящее Согласие на обработку персональных данных (далее – Согласие). Действуя свободно, своей волей и в своем интересе, а также подтверждая свою дееспособность, Пользователь дает свое согласие https://такси-56.рф на обработку своих персональных данных со следующими условиями:
                <br/>

                <br/>

                1. Данное Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и с их использованием.
                <br/>

                2. Согласие дается на обработку следующих моих персональных данных:
                <br/>

                <br/>
                Персональные данные, не являющиеся специальными или биометрическими:
                <br/>
                <ul>
                    <li>
                        номера контактных телефонов;
                    </li>
                    <li>
                        адреса электроннойпочты;
                    </li>
                    <li>
                        пользовательские данные (сведения о местоположении; тип и версия ОС;
                    </li>
                    <li>
                        тип и версия Браузера;
                    </li>
                    <li>
                        тип устройства и разрешение его экрана;
                    </li>

                    <li>
                        источник откуда пришел на сайт пользователь;
                    </li>
                    <li>
                        с какого сайта или по какой рекламе; язык ОС и Браузера;
                    </li>
                    <li>
                        какие страницы открывает и на какие кнопки нажимает пользователь; ip-адрес.)
                    </li>
                </ul>
                
                <br/>
                <br/>

                3. Персональные данные не являются общедоступными.
                <br/>
                <br/>

                4. Цель обработки персональных данных: обработка входящих запросов физических лиц с целью оказания консультирования; аналитики действий физического лица на веб-сайте и функционирования веб-сайта; проведение рекламных и новостных рассылок.
                <br/>
                <br/>

                5. Основанием для обработки персональных данных является: ст. 24 Конституции Российской Федерации; ст.6 Федерального закона №152-ФЗ«О персональных данных»; настоящее согласие на обработку персональных данных
                <br/>
                <br/>

                6. В ходе обработки с персональными данными будут совершены следующие действия: сбор; запись; систематизация; накопление; хранение; уточнение (обновление, изменение); извлечение; использование; передача (распространение, предоставление, доступ); блокирование; удаление; уничтожение.
                <br/>
                <br/>

                7. Персональные данные обрабатываются до отписки физического лица от рекламных и новостных рассылок. Также обработка персональных данных может быть прекращена по запросу субъекта персональных данных. Хранение персональных данных, зафиксированных на бумажных носителях осуществляется согласно Федеральному закону №125-ФЗ «Об архивном деле в Российской Федерации» и иным нормативно правовым актам в области архивного дела и архивного хранения.
                <br/>
                <br/>

                8. Согласие может быть отозвано субъектом персональных данных или его представителем путем направления письменного заявления или его представителю по адресу, указанному в начале данного Согласия.
                <br/>
                <br/>

                9. В случае отзыва субъектом персональных данных или его представителем согласия на обработку персональных данных https://такси-56.рф вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ«О персональных данных» от 27.07.2006 г.
                <br/>
                <br/>

                10. Настоящее согласие действует все время до момента прекращения обработки персональных данных, указанных в п.7 и п.8 данного Согласия.
                <br/>
                <br/>

            </p>
            <div className='policy-content__button-container'>
                <Link to="/" className='policy-content__button'>На главную</Link>
            </div>
        </div>
    )
}

export default PolicyContent