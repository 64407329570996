import React from 'react'
import taxiLogo from "../../assets/logo-license.svg"
import "./index.scss"
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer__container'>
                <div className='footer__top'>
                    <div className='footer__left'>
                        <img className='footer__logo' src={taxiLogo} alt="" />
                    </div>
                    <div className='footer__right'>
                        <p className='footer__contacts'>
                            Контакты
                        </p>
                        <div>
                            <p className='footer__text'>
                                Телефон: +73532421777 +79058402777 +79068402777
                            </p>
                            <p className='footer__text'>
                                Email: Registr.Litsensyi@yandex.ru
                            </p>
                            <p className='footer__text'>
                                Адрес: г.Оренбург, ул. Шоссейная, д.3/3
                            </p>
                        </div>
                    </div>
                </div>
                <div className='footer__bottom'>
                    <Link to="/policy">
                        <p className='footer__policy'>
                            Политика конфиденциальности
                        </p>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Footer