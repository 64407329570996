import React from 'react'
import "./index.scss"
import Button from '../Button'
import { Link } from 'react-router-dom'

const MainBanner = ({ openModal }) => {

    const submit = (e) => {
        e.preventDefault()
        const phone = e.target[0].value

        if(phone){
            fetch(`./mail.php`, {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                    type: "По номеру телефона",
                    phone: phone
                })
            }).then(res => {
                res.status === 200 && alert("Ваша заявка принята")
            }).catch(error => {
                alert("Ошибка при отправке запроса")
            })
        }
    }
    return (
        <div className='main-banner'>
            <div className='main-banner__left'>
                <form className='main-banner__form' onSubmit={submit}>
                    <p className='main-banner__top-title'>Получи лицензию</p>
                    <p className='main-banner__bottom-title'>На деятельность такси <span>до 10 дней</span></p>
                    <ol className='main-banner__list'>
                        <li>Работа без регистрации ООО и ИП</li>
                        <li>Гарантия лицензии 5 лет</li>
                        <li>Оформляем лицензию + мед. справку и путевые листы</li>
                        <li>Оформить онлайн за 5 минут</li>
                        <li>Консультация и помощь в регистрации самозанятого и ИП</li>
                    </ol>
                    <div className='main-banner__form-body'>
                        <Button onClick={openModal} className="main-banner__button">
                            Получить лицензию
                        </Button>
                        <p className='main-banner__policy'>
                            Отправляя заявку, вы согласны <Link to="/policy"><span>с политикой конфиденциальности</span></Link>
                        </p>
                    </div>
                </form>
            </div>
            <div className='main-banner__right'/>
        </div>
    )
}

export default MainBanner