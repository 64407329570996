
import React from 'react'
import { createPortal } from "react-dom";
import Header from "../components/Header";
import LicenseBlock from "../components/LicenseBlock";
import MainBanner from "../components/MainBanner";
import Price from "../components/Price";
import WhyLicense from "../components/WhyLicense";
import BottomBanner from "../components/BottomBanner";
import Modal from "../components/Modal";
import Footer from '../components/Footer';

const MainPage = () => {

    const [state, setState] = React.useState(false)

    const openModal = () => setState(true)
    
    return (
       <div>
            {
                createPortal(<Modal isOpen={state} handleCLose={() => setState(false)}/>, document.body)
            }
            <Header openModal={openModal}/>
            <MainBanner openModal={openModal}/>
            <LicenseBlock/>
            <WhyLicense/>
            <Price/>
            <BottomBanner openModal={openModal}/>
            <Footer/>
       </div>
    )
}

export default MainPage