import React from 'react'
import "./index.scss"
import modalImage from "../../assets/inside-car.jpg"
import Button from '../Button'
import { Link } from 'react-router-dom'
import ReactInputMask from 'react-input-mask'

const forType = {
    ph: "Физическое лицо",
    in: "ИП",
    ur: "Юридическое лицо"
}

const Modal = ({ isOpen, handleCLose }) => {

    const [state, setState] = React.useState(null)

     const submit = (e) => {
        e.preventDefault()

        const name = e.target[0].value
        const phone = e.target[1].value.replace(/ /g,'')
        const email = e.target[2].value

        const formdata = new FormData(e.target)

        formdata.append("entity", forType[state])

        if(name && phone && email && state){
            fetch(`./mail.php`, {
                method: "POST",
                body: formdata
            })
            .then(res => {
                return res.text()
            })
            .then(data => {
                handleCLose()
                alert(data)
            })
            .catch(error => {
                console.log(error)
                alert(error)
            })
        }
    }

    if(!isOpen) return null

    return (
        <div className='modal'>
            <div className='modal__back' onClick={handleCLose}/>
            <div className='modal__container'>
                <div className='modal__exit' onClick={handleCLose}/>
                <img className='modal__image' src={modalImage} alt="" />
                <form className='modal__contacts-form' onSubmit={submit}>
                    <p className='modal__title'>Оставьте контакты</p>
                    <p className='modal__description'>Наши специалисты помогут вам оформить лицензию по телефону</p>
                    <input required placeholder='Имя' className='modal__input' name="name" type="text" />
                    <ReactInputMask mask="+7 (999) 999-99-99">
                        {(props) => <input {...props} required type="tel" name='phone' placeholder='Номер телефона' className='modal__input'/>}
                    </ReactInputMask>
                    <input required placeholder='example@mail.ru' name='email' className='modal__input' type="text" />
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                        <div className='modal__checkbox'>
                            <input type="checkbox" id="ph" name="ph" checked={state === "ph"} onClick={() => setState("ph")}/>
                            <label for="ph">Физического лица</label>
                        </div>
                        <div className='modal__checkbox'>
                            <input type="checkbox" id="in" name="in" checked={state === "in"} onClick={() => setState("in")}/>
                            <label for="in">Индивидуального предпринимателя (ИП)</label>
                        </div>
                        <div className='modal__checkbox'>
                            <input type="checkbox" id="ur" name="ur" checked={state === "ur"} onClick={() => setState("ur")}/>
                            <label for="ur">Юридического лица (ООО)</label>
                        </div>
                    </div>
                    <Button type="submit">
                        Заказать звонок
                    </Button>
                    <p className='modal__agreement'>
                        Отправляя заявку, вы согласны с <Link to="/policy"><span>политикой конфиденциальности</span></Link>
                    </p>
                </form>
            </div>
        </div>
    )
}

export default Modal